<template>
  <v-form @submit.prevent="onSubmit">
    <div class="d-flex justify-center mt-10">
      <v-img
        max-width="66"
        height="95"
        src="@/assets/fire.svg"
      />
    </div>

    <v-card
      class="mx-auto my-12"
      max-width="374"
    >
      <v-card-title>
        Вход
      </v-card-title>

      <v-card-text>
        <v-alert
          v-if="error"
          outlined
          color="error"
          icon="mdi-warning"
        >
          {{ error }}
        </v-alert>

        <v-layout column>
          <v-text-field
            v-model="email"
            label="Email"
            required
          />

          <v-text-field
            v-model="password"
            label="Пароль"
            type="password"
            required
          />
        </v-layout>
      </v-card-text>

      <v-card-actions>
        <v-container>
          <v-btn
            color="primary"
            type="submit"
          >
            Войти
          </v-btn>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mapState } from 'vuex'
import { AUTH_LOGIN } from '@/store/actions.type'

export default {
  name: 'AuthView',

  data () {
    return {
      email: null,
      password: null
    }
  },

  methods: {
    onSubmit () {
      this.$store
        .dispatch(AUTH_LOGIN, {
          email: this.email,
          password: this.password
        })
        .then((data) => {
          this.$router.push({ path: `/${data.user.role}` })
        })
    }
  },

  computed: {
    ...mapState({
      error: state => state.auth.error
    })
  }
}
</script>
