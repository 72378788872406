import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import vuetify from '@/plugins/vuetify'
import Heading from '@/components/Heading.vue'
import Logo from '@/components/AppBar/Logo.vue'
import User from '@/components/AppBar/User.vue'
import NotificationPlugin from '@/plugins/notification'
import DateFilter from '@/filters/date'
import ApiService from '@/common/api.service'

import * as VueGoogleMaps from 'vue2-google-maps'
import GmapCluster from 'vue2-google-maps/dist/components/cluster'

Vue.config.productionTip = false

ApiService.init()

Vue.component('Heading', Heading)
Vue.component('Logo', Logo)
Vue.component('User', User)
Vue.filter('date', DateFilter)
Vue.use(NotificationPlugin)
Vue.component('GmapCluster', GmapCluster)

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAombYVhbGC_N_wyuttj408DsINEnYBwio',
    libraries: 'places'
  }
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
