import { STATUS_MESSAGE, ALERT_MESSAGE } from '@/store/actions.type'
import store from '@/store'

export default {
  install (Vue: any) {
    Vue.prototype.$notification = (text: string) => {
      store.dispatch(STATUS_MESSAGE, text).then()
    }

    Vue.prototype.$alert = (text: string) => {
      store.dispatch(ALERT_MESSAGE, text).then()
    }
  }
}
