<template>
  <v-dialog :value="displayDialog" max-width="500">
    <v-card>
      <v-card-title class="error white--text">Не заполнены поля</v-card-title>

      <v-card-text class="headline mt-5">
        {{ alertMessage }}
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="hideAlertMessage">Ок</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data () {
    return {
      timeout: 2500
    }
  },

  methods: {
    ...mapActions(['hideAlertMessage'])
  },

  computed: {
    ...mapGetters(['alertMessage']),
    displayDialog () {
      return this.alertMessage !== null
    }
  }
}
</script>
